import React from 'react'
import { Link } from 'gatsby'
import Layout from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import Careers from '../../components/Careers/Careers'

import Helmet from 'react-helmet'
import AOS from 'aos';

const prefix_string = "bs";
 class CareersPageBs extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {

    return (
      <Layout>
          <Helmet
          title="WELL – Izgradite svoju karijeru"
          meta={[
            { name: 'description', content: 'Pridružite se našem WELL timu i izgradite svoju karijeru.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} >
        </Helmet>
          <div id="well_careers">
          <Header prefix_string_page="bs"/>
            <div data-aos='fade-zoom-in'>
            
            <section id="p_well_careers" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">WELL Karijera</h1>

                <div className="content">
    <p><strong>
    Pridružite se našem WELL timu i izgradite svoju karijeru. 
    </strong></p>
    <p> Predstavite se, a mi ćemo stupiti u kontakt ako postoji uloga koja bi vam pristajala.</p>
<p>Ako imate bilo kakvih pitanja u vezi poslova u WELL-u, slobodno nas kontaktirajte na <a href="mailto:info@wellpromotion.ba" >jobs@wellpromotion.ba</a></p>

          <h3>Prijavite se odmah!</h3>
          <Careers prefix_string_page="/bs"/>
                </div>
                </div>            
</section>
        </div>
        </div>
          <Footer/>
      </Layout>
    )
  }
}


export default CareersPageBs